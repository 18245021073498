import React from 'react';
import { trackRecommendationsAction } from '@hotelplan/libs.tracking';
import { ProductRecommendationItem } from '@hotelplan/platform-graphql-api';
import ProductRecommendation from 'components/domain/product/ProductRecommendation';
import { Bd4TravelRecommendationFragment } from 'graphql/bd4Travel/BD4TravelRecommendation.generated';

type TProps = {
  item: ProductRecommendationItem | Bd4TravelRecommendationFragment;
  itemsLength: number;
  index: number;
  groupName: string;
};

const ProductRecommendationWrapper = ({
  item,
  index,
  groupName,
  itemsLength,
}: TProps) => {
  const type =
    item.__typename === 'ProductRecommendationItem' ? 'products' : 'bd4t';

  return (
    <ProductRecommendation
      recommendation={item}
      isSingle={itemsLength === 1}
      onClick={() => {
        trackRecommendationsAction({
          type,
          position: index + 1,
          title: groupName,
          products: { id: item.giata, price: item.price.amount },
        });
      }}
    />
  );
};

export default ProductRecommendationWrapper;
