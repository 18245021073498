import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { TransferInfo } from '@hotelplan/components.common.transfer';
import { ProductClass } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { getTargetPageData } from '@hotelplan/libs.router-link-utils';
import { formatDate, parseDateStr } from '@hotelplan/libs.utils';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import OfferBreadCrumbs from 'components/domain/offer/OfferBreadCrumbs';
import OfferDatesInformation from 'components/domain/offer/OfferDatesInformation';
import ThirdProductCard from 'components/domain/product-card/ThirdProductCard';
import { getProductIcons } from 'components/domain/travelTypes/TravelType.utils';
import FavourableContainer from 'components/domain/wishlist/FavourableContainer';
import { Bd4TravelRecommendationFragment } from 'graphql/bd4Travel/BD4TravelRecommendation.generated';
import { ProductRecommendationFragment } from 'graphql/product/ProductRecommendation.generated';
import { getProductIdFromUrl } from './Product.utils';

interface IProductRecommendationProps {
  recommendation:
    | ProductRecommendationFragment
    | Bd4TravelRecommendationFragment;
  onClick?(): void;
  className?: string;
  isSingle?: boolean;
}

export const smallProductCardStyles = ({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    boxShadow: 'none',
    flexDirection: ['column', 'column'],
    '.cardContent': {
      flexDirection: ['column', 'column'],
      p: 0,
    },
    '.cardImageWrap, img': {
      height: ['147px', '188px'],
    },
    '&&& .cardImageWrap': {
      minHeight: ['147px', '188px'],
      flexBasis: ['147px', '188px'],
      borderRadius: 0,
    },
    '.cardContentWrap': {
      flexBasis: [null, 'auto'],
    },
    '&&& .cardContentText': {
      px: 3,
      pt: 3,
    },
    '.disrupter': {
      position: 'absolute',
      top: '0',
      left: '0',
      zIndex: '1',
      border: 'none',
      ...FONT_SIZE.LARGE_BOLD,
      lineHeight: [1.5, 1],
    },
    '.disrupter ~ .travelType': {
      top: ['41px', '51px'],
      zIndex: '2',
    },
    '.productFeatures': {
      py: 2,
    },
    '.travelType': {
      m: 2,
    },
  });

const RecommendationProductCard = styled(ThirdProductCard)<{
  isSingle?: boolean;
  isDisrupter?: boolean;
}>(({ isSingle, isDisrupter, theme: { colors } }) =>
  isSingle
    ? sx2CssThemeFn({
        '.cardImageWrap': {
          flexBasis: [null, '50%'],
          height: [null, isDisrupter ? '355px' : '410px'],
          borderRadius: '4px 4px 0 0',
          borderTop: ['none', isDisrupter ? '1px solid' : 'none'],
          borderColor: [null, colors.lightGreyFirst],
        },
        '.travelType': {
          margin: 2,
        },
        '.disrupter': {
          border: 'none',
        },
        '.cardContentWrap': {
          p: 0,
          paddingTop: [0, 3],
        },
        '.card-rows': {
          '.cardContent': {
            borderRadius: '4px',
            p: 0,
          },
        },

        '.cardContentText': {
          height: '100%',
        },
        '.card-footer': {
          px: [0, 3],
        },
      })
    : smallProductCardStyles
);

const RecommendationOfferInfo = styled.span(
  ({ theme: { space, FONT_SIZE } }) => ({
    ...FONT_SIZE.MEDIUM,
    marginBottom: space[1],
  })
);

const FromTo = styled.span(({ theme: { FONT_SIZE } }) => ({
  ...FONT_SIZE.SMALL,
}));

const ProductFavContainer = styled(FavourableContainer)<{
  isSingle?: boolean;
  isDisrupter?: boolean;
}>(({ theme: { media }, isSingle, isDisrupter }) => ({
  height: '100%',
  '.wishlist-icon': {
    zIndex: 1,
    right: '15px',
    position: 'absolute',
    top: isDisrupter && isSingle ? '200px' : '160px',
    height: '28px',
    [media.tablet]: {
      top: !isSingle ? '200px' : isDisrupter ? '65px' : '15px',
    },
  },
}));

const ProductRecommendation: React.FC<IProductRecommendationProps> = props => {
  const { recommendation, className } = props;
  const { mobile } = useDeviceType();
  const [t] = useTranslation('common');

  const departureDate = formatDate(
    parseDateStr(recommendation.departureDate),
    'dd.MM.yyyy'
  );
  const returnDate = formatDate(
    parseDateStr(recommendation.returnDate),
    'dd.MM.yyyy'
  );
  const duration = t('common:nights.count', {
    count: recommendation.duration,
  });
  const productTravelType = getProductIcons(
    recommendation.type,
    recommendation.productClass
  );
  const showTransferInfo =
    !!recommendation.transferPrice || recommendation.transferIncluded;

  const ta = {
    rating: recommendation.taRating,
    reviews: recommendation.taReviews,
  };

  return (
    <ProductFavContainer
      withTooltip={!mobile}
      offerId={recommendation.offerId}
      productId={getProductIdFromUrl(recommendation.link.uri)}
      inWishlist={recommendation.inWishlist}
      isSingle={props.isSingle}
      isDisrupter={!!recommendation.disrupter}
    >
      <RecommendationProductCard
        onClick={props.onClick}
        disrupter={recommendation.disrupter}
        image={recommendation.image}
        link={getTargetPageData(recommendation.link)}
        subTitle={
          <OfferBreadCrumbs
            locations={props.recommendation.geoBreadcrumbs.map(
              ({ name = '' }) => {
                return {
                  label: name,
                  link: null,
                };
              }
            )}
          />
        }
        price={recommendation.price}
        productFeatures={recommendation.featureSummary}
        rating={(recommendation.hpRating || 0) / 10}
        testId="productRecommendationItem"
        productTravelType={productTravelType}
        title={recommendation.name}
        className={className}
        openMethod={!mobile ? '_blank' : undefined}
        isSingle={props.isSingle}
        isDisrupter={!!recommendation.disrupter}
        distanceInfo={
          props.isSingle && !mobile ? recommendation.distanceInfo : null
        }
        ta={props.isSingle && !mobile ? ta : null}
        productClass={
          props.isSingle && !mobile ? recommendation.productClass : null
        }
      >
        <OfferDatesInformation
          departureDate={departureDate}
          returnDate={returnDate}
          duration={duration}
        />
        {recommendation.fromTo && <FromTo>{recommendation.fromTo}</FromTo>}
        {![ProductClass.Cruise, ProductClass.Roundtrip].includes(
          recommendation.productClass
        ) && (
          <RecommendationOfferInfo>
            {productTravelType?.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  {t(item.title)}
                  {productTravelType.length - 1 !== i && <span> + </span>}
                </React.Fragment>
              );
            })}
            {recommendation.mealType && (
              <span>, {recommendation.mealType}</span>
            )}
            {showTransferInfo && (
              <TransferInfo
                price={recommendation.transferPrice}
                transferIncluded={recommendation.transferIncluded}
              />
            )}
          </RecommendationOfferInfo>
        )}
      </RecommendationProductCard>
    </ProductFavContainer>
  );
};

export default ProductRecommendation;
